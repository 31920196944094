
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SampleStyleGuide',
  data: () => ({
  }),
  methods: {
  },
});
